import React, { useEffect, useState } from 'react'
import LeftSideMenu from '../components/LeftSideMenu'
import UserNav from '../components/UserNav'
import Loader from '../components/Loader'

const MyProjects = () => {

    const [pageLoad, setPageLoad] = useState(true)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setPageLoad(false)
        }, 1000);
        return () => clearTimeout(timeout)
    },[])

    if(pageLoad) {
        return (
            <Loader />
        )
    }

    return (
        <>
            <div className='pagecontent'>
                <UserNav />
                <LeftSideMenu />
                <div className='pagecontentbox'>
                    <div className="pagetitlediv">
                        <h2 className="title">MyProjects</h2>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MyProjects