import React from 'react'
import { Image } from 'react-bootstrap'

const Loader = () => {
  return (
    <div className='loader-container'>
        <Image src={`${process.env.PUBLIC_URL}/images/rocket_anim.gif`} className='rocketloader' alt='loader'/>
    </div>
  )
}

export default Loader