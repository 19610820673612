import { apiSlice } from "./apiSlice";
import { config } from '../config/indexConfig';

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: config.api.endpoints.auth.login,
                method: 'POST',
                body: credentials,
            }),
        }),
        register: builder.mutation({
            query: (data) => ({
                url: config.api.endpoints.auth.register,
                method: 'POST',
                body: data,
            }),
        }),
        logout: builder.mutation({
            query: (refreshToken) => ({
                url: config.api.endpoints.auth.logout,
                method: 'POST',
                body: { refreshToken: refreshToken.token } // Send the actual token string
            }),
        }),
        updateUser: builder.mutation({
            query: ({ userId, userData }) => ({
                url: `/users/${userId}`,  // Adjust this URL based on your API endpoint
                method: 'PATCH',
                body: userData,
            }),
        }),
        addProject: builder.mutation({
            query: ({ userId, userData }) => ({
                url: `/users/${userId}/projects`,  // Adjust this URL based on your API endpoint
                method: 'POST',
                body: userData,
            }),
        }),
        getUserByUsername: builder.query({
            query: (username) => ({
                url: `/landing/${username}`,
                method: 'GET',
            }),
        }),
        getProjectByProjectname: builder.query({
            query: (projectName) => ({
                url: `/project/${projectName}`,
                method: 'GET',
            }),
        }),
    }),
});

export const { useLoginMutation, useRegisterMutation, useLogoutMutation, useUpdateUserMutation, useGetUserByUsernameQuery, useGetProjectByProjectnameQuery, useAddProjectMutation } = usersApiSlice;