import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Profile from './Profile';
import Appearance from './pages/Appearance';
import Dashboard from './pages/Dashboard';
import Settings from './pages/Settings';
import EditPortfolio from './edit-landing/EditPortfolio';
import Blog from './pages/Blog';
import Login from './pages/Auth/Login';
import Register from './pages/Auth/Register';
import PrivateRoute from './PrivateRoute';
import UserLandingPage from './UserLandingPage';
import Projects from './pages/Projects';
import CreateProject from './pages/CreateProject';
import MyProjects from './pages/MyProjects';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path='/' element={<Dashboard />} /> */}
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
        <Route path='/:projectName' element={<UserLandingPage />}/>
        
        <Route element={<PrivateRoute />}>
          <Route path='/user' element={<Profile />} />
          <Route path='/edit/devportfolio' element={<EditPortfolio />} />
          <Route path='/appearance' element={<Appearance />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/settings' element={<Settings />} />
          <Route path='/projects' element={<Projects />} />
          <Route path='/create-project' element={<CreateProject />} />
          <Route path='/my-projects' element={<MyProjects />} />
          <Route path='/blog' element={<Blog />} />
        </Route>

      </Routes>
    </BrowserRouter>
  )
}

export default App