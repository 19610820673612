import React from 'react'
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Contact = () => {
    return (
        <section className='contact' id='contact'>
            <Container>
                <div className='techheader text-center'>
                    <h3 className='h3'>Get In Touch</h3>
                    <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
                </div>
                <div className='btnflex mb-3'>
                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/github.svg' alt='stackicon' className='toolicon' /></span>Github</Link>
                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/linkedin.svg' alt='stackicon' className='toolicon' /></span>Linkedin</Link>
                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/mail.svg' alt='stackicon' className='toolicon' /></span>Email</Link>
                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/experience.svg' alt='stackicon' className='toolicon' /></span>Resume</Link>
                </div>
            </Container>
        </section>
    )
}

export default Contact