import React, { useState, useEffect } from 'react';
import LeftSideMenu from '../components/LeftSideMenu';
import UserNav from '../components/UserNav';
import { Link } from 'react-router-dom';
import { Button, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useUpdateUserMutation } from '../slices/usersApiSlice';
import Loader from '../components/Loader';
import { config } from '../config/indexConfig';

import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const templates = [
    {
        id: "default",
        title: 'Developer',
        name: 'developer',
        image:`${process.env.PUBLIC_URL}/images/folio.png`,
        editLink: '/edit/devportfolio',
        viewLink: '/',
    },
    {
        id: "real-estate",
        title: 'Real Estate',
        name: 'default',
        image:`${process.env.PUBLIC_URL}/images/site2.png`,
        editLink: '/edit/devportfolio',
    },
    {
        id: "doctor",
        title: 'Doctor',
        name: 'doctor',
        image:`${process.env.PUBLIC_URL}/images/site3.png`,
        editLink: '/edit/doctor',
    },
    {
        id: "clinic",
        title: 'Clinic',
        name: 'clinic',
        image:`${process.env.PUBLIC_URL}/images/site.png`,
        editLink: '/edit/clinic',
    }
];

const CreateProject = () => {
    const [userInfo, setUserInfo] = useState(() => JSON.parse(localStorage.getItem('userInfo')) || null);
    const [loading, setLoading] = useState(true);
    const [applyingTemplateId, setApplyingTemplateId] = useState(null); // Tracks template being applied
    const [updateUser] = useUpdateUserMutation();
    const location = useLocation()
    const navigate = useNavigate()

    const { projectName } = location.state || {}

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const storedUser = JSON.parse(localStorage.getItem('userInfo'));
                const accessToken = localStorage.getItem('accessToken');

                if (!storedUser?.id || !accessToken) {
                    throw new Error('User is not authenticated. Please log in again.');
                }

                const response = await fetch(`${config.api.baseUrl}/users/${storedUser.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }

                const userData = await response.json();
                setUserInfo(userData);
                localStorage.setItem('userInfo', JSON.stringify({
                    ...storedUser,
                    selectedTemplate: userData.selectedTemplate
                }));
            } catch (error) {
                console.error('Error fetching user data:', error);
                toast.error('Failed to load user data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleApplyTemplate = async (templateName) => {
        try {
            setApplyingTemplateId(templateName); // Set the template name being applied
            const storedUser = JSON.parse(localStorage.getItem('userInfo'));
            const accessToken = localStorage.getItem('accessToken');

            if (!storedUser || !accessToken) {
                toast.error('User is not authenticated. Please log in again.');
                return;
            }

            const result = await updateUser({
                userId: storedUser.id,
                userData: { selectedTemplate: templateName },
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            }).unwrap();

            console.log('Update result:', result);

            // Update local state and localStorage
            setUserInfo(prev => ({ ...prev, selectedTemplate: templateName }));
            const updatedUserInfo = { ...storedUser, selectedTemplate: templateName };
            localStorage.setItem('userInfo', JSON.stringify(updatedUserInfo));

            toast.success('Template applied successfully');

            // Navigate to the edit page with templateName and projectName
            const template = templates.find(t => t.name === templateName);
            navigate(template.editLink, { state: { projectName, templateName } });
        } catch (error) {
            console.error('Error applying template:', error);
            toast.error(error.data?.message || 'Failed to apply template');
        } finally {
            setApplyingTemplateId(null); // Reset after completion
        }
    };


    if (loading) {
        return <Loader />;
    }

    return (
        <>
            <div className='pagecontent'>
                <UserNav />
                <LeftSideMenu />
                <div className='pagecontentbox'>
                    <div className='pagebannerflex'>
                        <div className="pagetitle col-lg-6">
                            <h2 className="pagetitleh2">Great! Let's Build {projectName}!</h2>
                            <p className='content userpagecontent'>Discover the perfect theme for your LandingFast Store. </p>
                        </div>
                        <div className='pagebannerimgbg'>
                            <Image src={`${process.env.PUBLIC_URL}/images/art.png`} className='bannerimg' alt='bannerimg' />
                        </div>
                    </div>
                </div>
                <div className='pagecontentbox'>
                    <div className='themeflex'>
                        {templates.map((template) => (
                            <div key={template.id} className='themes'>
                                <div className='themeimgbg'>
                                    <Image src={template.image} className='themeimg' alt={template.title} />
                                </div>
                                <div className='applybtn'>
                                    {userInfo && userInfo.selectedTemplate === template.id ? (
                                        <>
                                            <Link to={template.editLink} className='btn customizebtn userbtn sitebtn'>
                                                Customize
                                            </Link>
                                            <Link to={template.viewLink} className='btn userbtn sitebtn'>
                                                View
                                            </Link>
                                        </>
                                    ) : (
                                        <>
                                            <Button
                                                className='btn userbtn sitebtn'
                                                onClick={() => handleApplyTemplate(template.name)} // Pass template.name
                                                disabled={applyingTemplateId === template.name} // Check with template.name
                                            >
                                                {applyingTemplateId === template.name ? 'Applying...' : 'Apply'}
                                            </Button>
                                            <Link to={`/preview/${template.id}`} className='prevlink'>
                                                Preview <span><Image src='images/linkprev.svg' className='previmg' alt='prevlink' /></span>
                                            </Link>
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default CreateProject;
