import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    userInfo: localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo'))
      : null,
    userId: localStorage.getItem('userId') || null,
    accessToken: localStorage.getItem('accessToken') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setCredentials: (state, action) => {
            const { user, tokens } = action.payload;
            state.userInfo = user;
            state.userId = user.id; // Save userId in the state
            state.accessToken = tokens.access.token;
            state.refreshToken = tokens.refresh.token;

            // Store tokens and userId in localStorage
            localStorage.setItem('userInfo', JSON.stringify(user));
            localStorage.setItem('userId', user.id); // Save userId in localStorage
            localStorage.setItem('accessToken', tokens.access.token);
            localStorage.setItem('refreshToken', tokens.refresh.token);
        },
        logout: (state) => {
            state.userInfo = null;
            state.userId = null;
            state.accessToken = null;
            state.refreshToken = null;

            // Clear all auth-related items from localStorage
            localStorage.removeItem('userInfo');
            localStorage.removeItem('userId');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
        },
    },
});

export const { setCredentials, logout } = authSlice.actions;

export default authSlice.reducer;
