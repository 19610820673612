

import React, { useState } from 'react'
import LeftSideMenu from '../components/LeftSideMenu'
import UserNav from '../components/UserNav'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Row, Col, Form, Tab, Nav, Image } from 'react-bootstrap'
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useLocation } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import axios from 'axios';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useAddProjectMutation } from '../slices/usersApiSlice'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from '../components/Loader'
import Experience from '../components/Experience'

const animatedComponents = makeAnimated();

const options = [
    // Frontend Development
    { value: 'HTML', label: 'HTML', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'CSS', label: 'CSS', icon: `${process.env.PUBLIC_URL}/images/icons/CSS3.svg` },
    { value: 'JavaScript', label: 'JavaScript', icon: `${process.env.PUBLIC_URL}/images/icons/JavaScript.svg` },
    { value: 'TypeScript', label: 'TypeScript', icon: `${process.env.PUBLIC_URL}/images/icons/TypeScript.svg` },
    { value: 'React', label: 'React', icon: `${process.env.PUBLIC_URL}/images/icons/React.svg` },
    { value: 'Vue', label: 'Vue', icon: `${process.env.PUBLIC_URL}/images/icons/Vue.js.svg` },
    { value: 'Angular', label: 'Angular', icon: `${process.env.PUBLIC_URL}/images/icons/AngularJS.svg` },
    { value: 'Svelte', label: 'Svelte', icon: `${process.env.PUBLIC_URL}/images/icons/Svelte.svg` },
    { value: 'Next.js', label: 'Next.js', icon: `${process.env.PUBLIC_URL}/images/icons/nextjs.svg` },
    { value: 'Gatsby', label: 'Gatsby', icon: `${process.env.PUBLIC_URL}/images/icons/Gatsby.svg` },
    { value: 'Bootstrap', label: 'Bootstrap', icon: `${process.env.PUBLIC_URL}/images/icons/Bootstrap.svg` },
    { value: 'Tailwind CSS', label: 'Tailwind CSS', icon: `${process.env.PUBLIC_URL}/images/icons/Tailwind-CSS.svg` },
    { value: 'Material-UI', label: 'Material-UI', icon: `${process.env.PUBLIC_URL}/images/icons/Material-UI.svg` },
    { value: 'jQuery', label: 'jQuery', icon: `${process.env.PUBLIC_URL}/images/icons/jQuery.svg` },

    // Backend Development
    { value: 'Node.js', label: 'Node.js', icon: `${process.env.PUBLIC_URL}/images/icons/node.svg` },
    { value: 'Express', label: 'Express', icon: `${process.env.PUBLIC_URL}/images/icons/Express.svg` },
    { value: 'Django', label: 'Django', icon: `${process.env.PUBLIC_URL}/images/icons/Django.svg` },
    { value: 'Flask', label: 'Flask', icon: `${process.env.PUBLIC_URL}/images/icons/Flask.svg` },
    { value: 'Ruby on Rails', label: 'Ruby on Rails', icon: `${process.env.PUBLIC_URL}/images/icons/Ruby.svg` },
    { value: 'Laravel', label: 'Laravel', icon: `${process.env.PUBLIC_URL}/images/icons/Laravel.svg` },
    { value: '.NET', label: '.NET', icon: `${process.env.PUBLIC_URL}/images/icons/dotnet.svg` },
    { value: 'Spring Boot', label: 'Spring Boot', icon: `${process.env.PUBLIC_URL}/images/icons/Spring.svg` },

    // Programming Languages
    { value: 'Python', label: 'Python', icon: `${process.env.PUBLIC_URL}/images/icons/Python.svg` },
    { value: 'Java', label: 'Java', icon: `${process.env.PUBLIC_URL}/images/icons/Java.svg` },
    { value: 'C++', label: 'C++', icon: `${process.env.PUBLIC_URL}/images/icons/(CPlusPlus.svg` },
    { value: 'C#', label: 'C#', icon: `${process.env.PUBLIC_URL}/images/icons/CSharp.svg` },
    { value: 'PHP', label: 'PHP', icon: `${process.env.PUBLIC_URL}/images/icons/PHP.svg` },
    { value: 'Swift', label: 'Swift', icon: `${process.env.PUBLIC_URL}/images/icons/Swift.svg` },
    { value: 'Kotlin', label: 'Kotlin', icon: `${process.env.PUBLIC_URL}/images/icons/Kotlin.svg` },
    { value: 'Go', label: 'Go', icon: `${process.env.PUBLIC_URL}/images/icons/Go.svg` },
    { value: 'Rust', label: 'Rust', icon: `${process.env.PUBLIC_URL}/images/icons/Rust.svg` },

    // Databases
    { value: 'MySQL', label: 'MySQL', icon: `${process.env.PUBLIC_URL}/images/icons/MySQL.svg` },
    { value: 'PostgreSQL', label: 'PostgreSQL', icon: `${process.env.PUBLIC_URL}/images/icons/PostgresSQL.svg` },
    { value: 'MongoDB', label: 'MongoDB', icon: `${process.env.PUBLIC_URL}/images/icons/MongoDB.svg` },
    { value: 'SQLite', label: 'SQLite', icon: `${process.env.PUBLIC_URL}/images/icons/SQLite.svg` },
    { value: 'Redis', label: 'Redis', icon: `${process.env.PUBLIC_URL}/images/icons/Redis.svg` },
    { value: 'Firebase', label: 'Firebase', icon: `${process.env.PUBLIC_URL}/images/icons/Firebase.svg` },
    { value: 'Oracle Database', label: 'Oracle Database', icon: `${process.env.PUBLIC_URL}/images/icons/Oracle.svg` },

    // Cloud & DevOps
    { value: 'AWS', label: 'AWS', icon: `${process.env.PUBLIC_URL}/images/icons/AWS.svg` },
    { value: 'Azure', label: 'Azure', icon: `${process.env.PUBLIC_URL}/images/icons/Azure.svg` },
    { value: 'Google Cloud', label: 'Google Cloud', icon: `${process.env.PUBLIC_URL}/images/icons/Google-Cloud.svg` },
    { value: 'Docker', label: 'Docker', icon: `${process.env.PUBLIC_URL}/images/icons/Docker.svg` },
    { value: 'Kubernetes', label: 'Kubernetes', icon: `${process.env.PUBLIC_URL}/images/icons/Kubernetes.svg` },
    { value: 'Jenkins', label: 'Jenkins', icon: `${process.env.PUBLIC_URL}/images/icons/Jenkins.svg` },
    { value: 'Git', label: 'Git', icon: `${process.env.PUBLIC_URL}/images/icons/Git.svg` },
    { value: 'GitHub', label: 'GitHub', icon: `${process.env.PUBLIC_URL}/images/icons/GitHub.svg` },
    { value: 'GitLab', label: 'GitLab', icon: `${process.env.PUBLIC_URL}/images/icons/GitLab.svg` },
    { value: 'CircleCI', label: 'CircleCI', icon: `${process.env.PUBLIC_URL}/images/icons/CircleCI.svg` },

    // Design & Prototyping
    { value: 'Adobe Photoshop', label: 'Adobe Photoshop', icon: `${process.env.PUBLIC_URL}/images/icons/Adobe-Photoshop.svg` },
    { value: 'Adobe Illustrator', label: 'Adobe Illustrator', icon: `${process.env.PUBLIC_URL}/images/icons/Adobe-Illustrator.svg` },
    { value: 'Figma', label: 'Figma', icon: `${process.env.PUBLIC_URL}/images/icons/Figma.svg` },
    { value: 'Sketch', label: 'Sketch', icon: `${process.env.PUBLIC_URL}/images/icons/Sketch.svg` },
    { value: 'Adobe XD', label: 'Adobe XD', icon: `${process.env.PUBLIC_URL}/images/icons/Adobe-XD.svg` },
    { value: 'InVision', label: 'InVision', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Canva', label: 'Canva', icon: `${process.env.PUBLIC_URL}/images/icons/Canva.svg` },

    // Digital Marketing Tools
    { value: 'Google Analytics', label: 'Google Analytics', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Google Ads', label: 'Google Ads', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Facebook Ads', label: 'Facebook Ads', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'HubSpot', label: 'HubSpot', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Mailchimp', label: 'Mailchimp', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Hootsuite', label: 'Hootsuite', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'SEMrush', label: 'SEMrush', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Ahrefs', label: 'Ahrefs', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Salesforce', label: 'Salesforce', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Buffer', label: 'Buffer', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },

    // Content Management Systems
    { value: 'WordPress', label: 'WordPress', icon: `${process.env.PUBLIC_URL}/images/icons/WordPress.svg` },
    { value: 'Drupal', label: 'Drupal', icon: `${process.env.PUBLIC_URL}/images/icons/Drupal.svg` },
    { value: 'Joomla', label: 'Joomla', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Contentful', label: 'Contentful', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Strapi', label: 'Strapi', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Shopify', label: 'Shopify', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Magento', label: 'Magento', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },

    // Project Management
    { value: 'Trello', label: 'Trello', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Jira', label: 'Jira', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Asana', label: 'Asana', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Notion', label: 'Notion', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Monday.com', label: 'Monday.com', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Slack', label: 'Slack', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` },
    { value: 'Microsoft Teams', label: 'Microsoft Teams', icon: `${process.env.PUBLIC_URL}/images/icons/HTML5.svg` }
];


const EditPortfolio = () => {

    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const [loading, setLoading] = useState(false);
    const location = useLocation()
    const navigate = useNavigate()
    const { projectName } = location.state || {}
    const { templateName } = location.state || {}

    const [isCollapsed, setIsCollapsed] = useState(false);

    const toggleCollapse = () => {
        setIsCollapsed((prev) => !prev);
    };

    const [prevImage, setPrevImage] = useState(`${process.env.PUBLIC_URL}/images/pro.jpg`)
    const [prevProjImage, setPrevProjImage] = useState(`${process.env.PUBLIC_URL}/images/fitmate.png`)

    const [addProject] = useAddProjectMutation(); // Use the mutation from your Redux Toolkit
    const [formData, setFormData] = useState({
        projectName: projectName,
        template: templateName,
        proImgUrl: '',
        tagline: '',
        heroHeading: '',
        heroSubHeading: '',
        facebookUrl: '',
        xUrl: '',
        InstaUrl: '',
        LinkUrl: '',
        GithubUrl: '',
        DribbleUrl: '',
        BehanceUrl: '',
        resume: '',
    });
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [userProjects, setUserProjects] = useState([{ projectName: '', projectLink: '', projectImage: null, previewImage: null }])

    const [experiences, setExperiences] = useState([{ company: '', role: '', from: null, to: null }]); // Initial state for experiences

    const handleExperienceChange = (index, field, value) => {
        const newExperiences = [...experiences];
        newExperiences[index][field] = value;
        setExperiences(newExperiences);
    };

    const handleProjectsChange = (index, field, value) => {
        const newProjects = [...userProjects]
        newProjects[index][field] = value
        setUserProjects(newProjects)
    }

    const addProjects = () => {
        setUserProjects([...userProjects, { projectName: '', projectLink: '', projectImage: null, previewImage: null }])
    }

    const addExperience = () => {
        setExperiences([...experiences, { company: '', role: '', from: null, to: null }]); // Add a new experience entry
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleFileUpload = async (file, type) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'ml_default'); // Replace with your Cloudinary upload preset

        const folder = type === 'image' ? 'project_images' : 'resume';
        formData.append('folder', folder);

        try {
            const resourceType = type === 'image' ? 'image' : 'raw';

            const response = await axios.post(`https://api.cloudinary.com/v1_1/do2pizjsh/${resourceType}/upload`, formData);
            return response.data.secure_url; // Return the uploaded image's URL
        } catch (error) {
            toast.error(`Error uploading ${type} to Cloudinary!`);
            console.log(error)
            throw error;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            let uploadedImageUrl = formData.proImgUrl;
            let uploadedResumeUrl = formData.resume;

            if (formData.proImgUrl instanceof File) {
                uploadedImageUrl = await handleFileUpload(formData.proImgUrl, 'image');
            }

            if (formData.resume instanceof File) {
                uploadedResumeUrl = await handleFileUpload(formData.resume, 'resume');
            }

            const projectImageUrls = await Promise.all(
                userProjects.map(async (project) => {
                    if (project.image instanceof File) {
                        return await handleFileUpload(project.image, 'image'); // Upload project image
                    }
                    return project.image; // Return existing URL if no new image is uploaded
                })
            );

            const updatedProjects = userProjects.map((project, index) => ({
                ...project,
                projectImage: projectImageUrls[index] // Add the uploaded image URL to the project
            }));

            setFormData(prev => ({
                ...prev,
                proImgUrl: uploadedImageUrl,
                resume: uploadedResumeUrl,
                userProjects: userProjects.map((project, index) => ({
                    ...project,
                    projectImage: projectImageUrls[index] // Add the uploaded image URL to the project
                }))
            }));

            // Prepare the payload
            const payload = {
                projectName: formData.projectName,
                template: formData.template,
                landingPageData: {
                    proImgUrl: uploadedImageUrl,
                    tagline: formData.tagline,
                    heroHeading: formData.heroHeading,
                    heroSubHeading: formData.heroSubHeading,
                    facebookUrl: formData.facebookUrl,
                    xUrl: formData.xUrl,
                    InstaUrl: formData.InstaUrl,
                    LinkUrl: formData.LinkUrl,
                    GithubUrl: formData.GithubUrl,
                    DribbleUrl: formData.DribbleUrl,
                    BehanceUrl: formData.BehanceUrl,
                    resume: uploadedResumeUrl,
                    skills: selectedSkills,
                    experiences: experiences,
                    userProjects: updatedProjects,
                },
            };

            // Pass userId and accessToken to the mutation
            const response = await addProject({
                userId,
                userData: payload,
                token: accessToken,
            }).unwrap();


            setTimeout(() => {
                toast.success('Website Published successfully!');
            }, 1000);
            const newTab = window.open(`/${formData.projectName}`, '_blank');
            if (newTab) {
                newTab.focus();
            }
            navigate('/dashboard');
        } catch (error) {
            console.error('Error adding project:', error);
            toast.error('Failed to create project.');
        }
        finally {
            setLoading(false);
        }
    };

    const handleInputChange = (e) => {
        const { name, value, type, files } = e.target;

        if (type === 'file') {
            if (files.length > 0) {
                const file = files[0];

                if (name === 'proImgUrl') {
                    // Handle image file
                    const previewUrl = URL.createObjectURL(file);
                    setFormData(prev => ({ ...prev, [name]: file }));
                    setPrevImage(previewUrl);
                }
                if (name === 'projectImage') {
                    const previewProjectUrl = URL.createObjectURL(file);
                    setFormData(prev => ({ ...prev, [name]: file }));
                    setPrevProjImage(previewProjectUrl);
                }
                else if (name === 'resume') {
                    // Handle PDF file
                    if (file.type === 'application/pdf') {
                        setFormData(prev => ({ ...prev, [name]: file }));
                    } else {
                        toast.error('Please upload only PDF files for resume');
                        e.target.value = ''; // Reset input
                    }
                }
            }
        } else {
            setFormData(prev => ({ ...prev, [name]: value }));
        }
    };



    return (
        <>
            {loading
                ?
                <Loader />
                :
                <div className='pagecontent'>
                    <UserNav />
                    <LeftSideMenu />
                    <div className='pagecontentbox'>
                        <div className='pagebannerflex'>
                            <div className="pagetitle col-lg-6">
                                <h2 className="pagetitleh2">Personalize Your {projectName} Website using {templateName} Theme!</h2>
                                <p className='content userpagecontent'>Welcome to your profile editor! Customize your portfolio by updating your information below. Your details will be displayed on your portfolio page, giving visitors a snapshot of your skills, experience, and personality. Make sure to save changes to keep your portfolio current and engaging!</p>
                            </div>
                            <div className='pagebannerimgbg'>
                                <Image src={`${process.env.PUBLIC_URL}/images/editbanner.webp`} className='bannerimg' alt='bannerimg' />
                            </div>
                        </div>
                    </div>
                    <div className='pagecontentbox'>
                        <div className='editflexbox'>
                            <Tab.Container defaultActiveKey="hero">
                                <div className='landingmenusection'>
                                    <div>
                                        <Nav variant="pills" className="flex-column">
                                            <Nav.Item>
                                                <Nav.Link eventKey="hero">Hero Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="tech">Tech Stacks / Tools Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="experience">Professional Experience Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="pow">Proof Of Work Section</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="submit">Submit</Nav.Link>
                                            </Nav.Item>
                                        </Nav>
                                    </div>
                                </div>

                                <div className='landingformsection'>
                                    <Form onSubmit={handleSubmit}>
                                        <Tab.Content>
                                            <Tab.Pane eventKey="hero">
                                                <div className='landingsectionflex'>
                                                    <div className='landingsections'>
                                                        <div className='profileimgbox'>
                                                            <div className='my-2'>
                                                                <span className="proimgbg"><img src={prevImage} alt="profileimage" className="proimg" /></span>
                                                            </div>
                                                            <h6 className='h6'>Tailor Your Profile Display</h6>
                                                            <p className='userpagecontent'>Update your profile photo to personalize your portfolio and make a lasting impression!</p>
                                                            <Row>
                                                                <Col>
                                                                    <Form.Group controlId="formFile" className="mb-3">
                                                                        <Form.Label>Upload Your Profile Picture</Form.Label>
                                                                        <Form.Control type="file"
                                                                            name="proImgUrl"
                                                                            accept="image/*"
                                                                            onChange={handleInputChange} />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="formGridAddress1">
                                                                        <Form.Label>Profile Tagline</Form.Label>
                                                                        <Form.Control
                                                                            name="tagline"
                                                                            value={formData.tagline}
                                                                            onChange={handleChange}
                                                                            placeholder="Devmaster"
                                                                        />
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                            {/* <Button className='btn sitebtn borderbtn userbtn me-3'>Upload New</Button> */}
                                                        </div>
                                                    </div>
                                                    <div className='landingsections'>
                                                        <div className='biobg'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>Tailor Your Profile Display</h6>
                                                            </div>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                <Form.Label>Edit Your Heading
                                                                </Form.Label>
                                                                <Form.Control
                                                                    name="heroHeading"
                                                                    value={formData.heroHeading}
                                                                    onChange={handleChange}
                                                                    as="textarea"
                                                                    rows={3}
                                                                />
                                                            </Form.Group>
                                                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                <Form.Label>Edit Your Bio
                                                                </Form.Label>
                                                                <Form.Control
                                                                    name="heroSubHeading"
                                                                    value={formData.heroSubHeading}
                                                                    onChange={handleChange}
                                                                    type='text'
                                                                    as="textarea"
                                                                    rows={3}
                                                                />
                                                            </Form.Group>
                                                            <div className='d-flex gap-2'>
                                                                <Button className='btn sitebtn userbtn aibtn'>Use AI</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='landingsections'>
                                                        <div className='socmedialinks'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>Social Media Links</h6>
                                                            </div>
                                                            <div>
                                                                <SimpleBar style={{ maxHeight: 260 }}>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                                        <Form.Label>Facebook
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            name="facebookUrl"
                                                                            type="text"
                                                                            value={formData.facebookUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="facebook.com/john"
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>X
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            name="xUrl"
                                                                            type="text"
                                                                            value={formData.xUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="facebook.com/john"
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>Instagram
                                                                        </Form.Label>
                                                                        <Form.Control
                                                                            name="InstaUrl"
                                                                            type="text"
                                                                            value={formData.InstaUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="facebook.com/john"
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>Linkedin
                                                                        </Form.Label>
                                                                        <Form.Control type="text"
                                                                            name='LinkUrl'
                                                                            value={formData.LinkUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="linkedin.com/john" />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>Github
                                                                        </Form.Label>
                                                                        <Form.Control type="text"
                                                                            name='GithubUrl'
                                                                            value={formData.GithubUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="github.com/john" />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>Dribble
                                                                        </Form.Label>
                                                                        <Form.Control type="text"
                                                                            name='DribbleUrl'
                                                                            value={formData.DribbleUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="dribble.com/john" />
                                                                    </Form.Group>
                                                                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea2">
                                                                        <Form.Label>Behance
                                                                        </Form.Label>
                                                                        <Form.Control type="text"
                                                                            name='BehanceUrl'
                                                                            value={formData.BehanceUrl}
                                                                            onChange={handleChange}
                                                                            placeholder="behance.com/john" />
                                                                    </Form.Group>
                                                                </SimpleBar>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='landingsections'>
                                                        <div className='resumesection'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>Add Your Updated Resume</h6>
                                                                <Form.Group controlId="formFile" className="mb-3">
                                                                    <Form.Label>Upload your resume</Form.Label>
                                                                    <Form.Control
                                                                        type="file"
                                                                        name='resume'
                                                                        accept=".pdf"
                                                                        onChange={handleInputChange}
                                                                    />
                                                                    <small>Please upload only .pdf format</small>
                                                                </Form.Group>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="tech">
                                                <div className='landingsectionflex'>
                                                    <div className='landingsections'>
                                                        <div className='stacksbox'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>
                                                                    Skills & Tools</h6>
                                                                <p className='userpagecontent'>Select the skills and tools that showcase your expertise across development, design, and marketing. Highlight what powers your projects!</p>
                                                            </div>
                                                            <Select
                                                                closeMenuOnSelect={false}
                                                                components={animatedComponents}
                                                                isMulti
                                                                options={options}
                                                                onChange={setSelectedSkills} // Update selected skills on change
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="experience">
                                                <div className='landingsectionflex'>
                                                    <div className='landingsections'>
                                                        <div className='experiencebox'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>Your Career Highlights</h6>
                                                                <p className='userpagecontent'>Showcase your professional journey and highlight the roles that define your expertise!</p>
                                                            </div>

                                                            {experiences.map((experience, index) => (
                                                                <div key={index} className='formflex'>
                                                                    <Form.Group controlId={`company-${index}`}>
                                                                        <Form.Label>Enter Company Name</Form.Label>
                                                                        <Form.Control
                                                                            name="company"
                                                                            type="text"
                                                                            placeholder="Zoho"
                                                                            value={experience.company}
                                                                            onChange={(e) => handleExperienceChange(index, 'company', e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group controlId={`role-${index}`}>
                                                                        <Form.Label>Role</Form.Label>
                                                                        <Form.Control
                                                                            name="role"
                                                                            type="text"
                                                                            placeholder="Frontend Developer"
                                                                            value={experience.role}
                                                                            onChange={(e) => handleExperienceChange(index, 'role', e.target.value)}
                                                                        />
                                                                    </Form.Group>
                                                                    <Form.Group controlId={`from-${index}`} className='d-flex flex-column'>
                                                                        <Form.Label>From</Form.Label>
                                                                        <DatePicker selected={experience.from}
                                                                            onChange={(date) => handleExperienceChange(index, 'from', date)} />
                                                                    </Form.Group>
                                                                    <Form.Group controlId={`to-${index}`} className='d-flex flex-column'>
                                                                        <Form.Label>To</Form.Label>
                                                                        <DatePicker selected={experience.to}
                                                                            onChange={(date) => handleExperienceChange(index, 'to', date)} />
                                                                    </Form.Group>
                                                                </div>
                                                            ))}

                                                            <div className='mt-3'>
                                                                <Button onClick={addExperience} className="mt-3 sitebtn userbtn">Add More</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="pow">
                                                <div className='landingsectionflex'>
                                                    <div className='landingsections'>
                                                        <div className='projectsection'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>Showcase Your Projects</h6>
                                                            </div>

                                                            {userProjects.map((project, index) => (
                                                                <div key={index} className='my-2'>
                                                                    <div className='contentboxtitle'>
                                                                        <Button className='sitebtn userbtn' onClick={toggleCollapse}>
                                                                            Project {index + 1}
                                                                            {/* {isCollapsed ? "Hide Project Details" : "Show Project Details"} */}
                                                                        </Button>
                                                                    </div>
                                                                    {isCollapsed && (
                                                                        <div className='mt-2'>
                                                                            <div className='my-2'>
                                                                                <span className="proimgbg">
                                                                                    {userProjects[index].previewImage 
                                                                                    ?
                                                                                    <Image
                                                                                    src={userProjects[index].previewImage}
                                                                                    alt="Project Preview"
                                                                                    className='proimg'
                                                                                />
                                                                                :
                                                                                <Image src={prevProjImage} alt="profileimage" className="proimg" />
                                                                                }
                                                                                </span>
                                                                            </div>
                                                                            <Form.Group controlId="formFile" className="mb-3">
                                                                                <Form.Label>Upload Your Project Screenshot</Form.Label>
                                                                                <Form.Control
                                                                                    type="file"
                                                                                    name={`projectImage-${index}`}
                                                                                    accept="image/*"
                                                                                    onChange={(e) => {
                                                                                        const file = e.target.files[0];
                                                                                        if (file) {
                                                                                            const previewUrl = URL.createObjectURL(file); // Generate preview URL
                                                                                            const updatedProjects = [...userProjects];
                                                                                            updatedProjects[index] = {
                                                                                                ...updatedProjects[index],
                                                                                                projectImage: file,
                                                                                                previewImage: previewUrl,
                                                                                            };
                                                                                            setUserProjects(updatedProjects);
                                                                                        }
                                                                                    }}
                                                                                />

                                                                            </Form.Group>
                                                                            <Form.Group className="mb-3" controlId={`projectName-${index}`}>
                                                                                <Form.Label>Project Name
                                                                                </Form.Label>
                                                                                <Form.Control type="text" placeholder="project"
                                                                                    value={project.projectName}
                                                                                    onChange={(e) => handleProjectsChange(index, 'projectName', e.target.value)}
                                                                                />
                                                                            </Form.Group>
                                                                            <Form.Group className="mb-3" controlId={`projectLink-${index}`}>
                                                                                <Form.Label>Project Link
                                                                                </Form.Label>
                                                                                <Form.Control type="text" placeholder="project.com"
                                                                                    value={project.projectLink}
                                                                                    onChange={(e) => handleProjectsChange(index, 'projectLink', e.target.value)}
                                                                                />
                                                                            </Form.Group>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}


                                                            <div className='mt-3'>
                                                                <Button onClick={addProjects} className='btn sitebtn userbtn'>Add Project</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                            <Tab.Pane eventKey="submit">
                                                <div className='landingsectionflex'>
                                                    <div className='landingsections'>
                                                        <div className='biobg'>
                                                            <div className='contentboxtitle'>
                                                                <h6 className='h6'>Great! Publish Your Site!</h6>
                                                                <p className='userpagecontent'>Stand Out with a Stunning Online Profile!</p>
                                                            </div>
                                                            <div className='d-flex gap-2'>
                                                                <Button type='submit' className='btn sitebtn userbtn'>Publish
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tab.Pane>
                                        </Tab.Content>
                                    </Form>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            }
            <ToastContainer />
        </>
    )
}

export default EditPortfolio