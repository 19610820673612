import React from 'react'
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Projects = () => {
    return (
        <section className='projects' id='projects'>
            <Container>
                <div className='techheader text-center'>
                    <Badge className='custombadge my-3'>Projects <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
                    <h3 className='h3'>Proof Of Work</h3>
                    <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
                </div>
                <div className='projectflex'>
                    <div className='projectflexcont text-center'>
                        <span className='projimgbg'>
                            <Image src='images/fitmate.png' alt='projects' className='projectimg'/>
                        </span>
                        <h6 className='h6 mt-3'>FitMate</h6>
                        <Link className='btn sitebtn mt-2' to='#'>Live Site</Link>
                    </div>
                    <div className='projectflexcont text-center'>
                        <span className='projimgbg'>
                            <Image src='images/shop.png' alt='projects' className='projectimg'/>
                        </span>
                        <h6 className='h6 mt-3'>Dukaan</h6>
                        <Link className='btn sitebtn mt-2' to='#'>Live Site</Link>
                    </div>
                    <div className='projectflexcont text-center'>
                        <span className='projimgbg'>
                            <Image src='images/solwallet.png' alt='projects' className='projectimg'/>
                        </span>
                        <h6 className='h6 mt-3'>SolWallet - Web3</h6>
                        <Link className='btn sitebtn mt-2' to='#'>Live Site</Link>
                    </div>
                    <div className='projectflexcont text-center'>
                        <span className='projimgbg'>
                            <Image src='images/fitmate.png' alt='projects' className='projectimg'/>
                        </span>
                        <h6 className='h6 mt-3'>HTML</h6>
                        <Link className='btn sitebtn mt-2' to='#'>Live Site</Link>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Projects