import React, { useEffect, useState } from 'react'
import { Container, Col, Row, Image, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import { useLoginMutation } from '../../slices/usersApiSlice';
import { setCredentials } from '../../slices/authSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = () => {

  useEffect(() => {
    document.body.classList.add('authpage')

    return () => {
      document.body.classList.remove('authpage')
    }
  }, [])

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [login, { isLoading }] = useLoginMutation()

  const { userInfo } = useSelector((state) => state.auth)

  useEffect(() => {
    if (userInfo) {
      navigate('/dashboard')
    }
  }, [navigate, userInfo])

  const submitHandler = async (e) => {
    e.preventDefault()
    try {
      const res = await login({ email, password }).unwrap()
      dispatch(setCredentials({ ...res }))
      navigate('/dashboard')
    } catch (err) {
      console.log(err?.data?.message || err.error)
      toast.error(err?.data?.message || err.error)
    }
  }

  return (
    <>
      <Image src='images/triangle.svg' className='authbgimg' alt='authbg' />
      <div className='formcontainer'>
        <div className="formboxbg">
          <div className='authtitlebg mb-4'>
            <h2 className='mb-3'>LandingFast 🚀</h2>
            <h6>Login To The Application</h6>
          </div>
          <Form onSubmit={submitHandler}>
            <Form.Group className="mb-3" controlId="formGridAddress1">
              <Form.Label>Email</Form.Label>
              <Form.Control name="email" placeholder='email.com' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formGridPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control name="password" placeholder='*******' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <div className="text-center">
              <button className='btn w-100 authbtn sitebtn' type="submit" disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Login'}
              </button>
            </div>
          </Form>
        </div>
        <div>
          <h6>Don't Have An Account <Link to='/register'>Get Started!</Link></h6>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default Login