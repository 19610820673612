import React from 'react'
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import { Link } from 'react-router-dom';

const Hero = () => {
    return (
        <>
            <section className="homebannerbg">
                <Container className='sitebannercontent'>
                    <Row>
                        <Col lg={8} className="homecol text-center mx-auto">
                            <div className="bannercontent">
                                <div className='mt-5'>
                                    <span className='proimgbg'><Image src='/images/v.webp' alt='profileimage' className='proimg' /></span>
                                </div>
                                <Badge className='custombadge'>DevMaster <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
                                <h2 className="maintitl">Hello! It's Vasanth Chandrasekar</h2>
                                <div className='col-lg-6 mx-auto'>
                                    <p className="content">
                                        Our mission is to transform your business ideas into reality
                                        with cutting-edge software solutions.
                                    </p>
                                </div>
                                <div className='btnflex mb-3'>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/github.svg' alt='stackicon' className='toolicon'/></span>Github</Link>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/linkedin.svg' alt='stackicon' className='toolicon'/></span>Linkedin</Link>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/mail.svg' alt='stackicon' className='toolicon'/></span>Email</Link>
                                    <Link to="#" className='btn sitebtn'><span className='me-2'><Image src='images/experience.svg' alt='stackicon' className='toolicon'/></span>Resume</Link>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Hero