import React from 'react'
import HomeHeader from './components/HomeHeader'
import Hero from './components/Hero'
import Stacks from './components/Stacks'
import Experience from './components/Experience'
import Projects from './components/Projects'
import Contact from './components/Contact'

import { useParams } from 'react-router-dom'

const Profile = () => {
    const {username} = useParams()
    return (
        <>
            <HomeHeader />
            <Hero username={username} />
            <Stacks />
            <Experience />
            <Projects />
            <Contact />
        </>
    )
}

export default Profile