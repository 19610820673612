import React, { useEffect } from 'react'
import { Image, Button, Form, Nav, Navbar, NavDropdown, Dropdown } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../slices/authSlice';
import { useLogoutMutation } from '../slices/usersApiSlice';

const UserNav = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [logoutApiCall] = useLogoutMutation();
    
    // Add this line to get userInfo from Redux state
    const { userInfo } = useSelector((state) => state.auth);

    const handleLogout = () => {
        dispatch(logout()); // This will clear localStorage and Redux state
        navigate('/login');
    };

    useEffect(() => {
        document.body.classList.add('usernav')
        return () => {
            document.body.classList.remove('usernav')
        }
    }, [])

    return (
        <Navbar
            collapseOnSelect
            expand="lg"
            className="bg-body-tertiary usernavbar"
        >
            {/* <div className="mobiletoggle">
                <Button type="button" id="sidebarCollapse" className="btn sidebtntoggle" fdprocessedid="p99kb">
                    <img src="#" />
                </Button>
            </div> */}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav className='me-auto'>
                    <div className="pagetitlediv">
                        <h2 className="title">Welcome, {userInfo?.name}! 👋</h2>
                    </div>
                </Nav>
                <Nav>
                    <Dropdown className='navmenudrop'>
                        <Dropdown.Toggle id="dropdown-basic">
                            <Image src={`${process.env.PUBLIC_URL}/images/menu.png`} className='menuimg' alt='menu' />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item href="/profile">Profile</Dropdown.Item>
                            <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default UserNav