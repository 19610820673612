import React from 'react'
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";

const Stacks = () => {
    return (
        <section className='techstacks' id='techstacks'>
            <Container>
                <div className='techheader text-center'>
                    <Badge className='custombadge my-3'>Tech Stack <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
                    <h3 className='h3'>My Tech Toolbox</h3>
                    <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
                </div>
                <div className='stackflex col-lg-8 mx-auto mt-4'>
                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>
                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>
                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>

                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>
                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>
                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>
                    <div className='stackflexbg'>
                        <Image src='images/icons/HTML5.svg' className='stackicons' alt='stack' />
                        <h6 className='h6 mt-3'>HTML</h6>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default Stacks