import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { config } from '../config/indexConfig';
import { setCredentials, logout } from './authSlice';

const baseQuery = fetchBaseQuery({
  baseUrl: config.api.baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const accessToken = getState().auth.accessToken;
    if (accessToken) {
      headers.set('authorization', `Bearer ${accessToken}`);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
  
    if (result?.error?.status === 401) {
      const refreshToken = api.getState().auth.refreshToken;
      const refreshResult = await baseQuery(
        {
          url: config.api.endpoints.auth.refreshToken,
          method: 'POST',
          body: { refreshToken },
        },
        api,
        extraOptions
      );
  
      if (refreshResult?.data) {
        api.dispatch(setCredentials(refreshResult.data));
        result = await baseQuery(args, api, extraOptions);
      } else {
        api.dispatch(logout());
      }
    }
  
    return result;
  };

export const apiSlice = createApi({
  baseQuery,
  endpoints: (builder) => ({}),
});