export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://landingfast.onrender.com/v1/';
export const PROD_API_BASE_URL = process.env.PROD_API_BASE_URL || 'http://localhost:3001/v1/';

export const config = {
  api: {
    baseUrl: API_BASE_URL,
    endpoints: {
      auth: {
        login: '/auth/login',
        register: '/auth/register',
        refreshToken: '/auth/refresh-tokens',
        logout: '/auth/logout',
      },
    },
  },
};