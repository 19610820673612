import React, { useEffect, useState } from 'react';
import LeftSideMenu from '../components/LeftSideMenu';
import UserNav from '../components/UserNav';
import { Button, Form, Image, Spinner } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { config } from '../config/indexConfig';
import Loader from '../components/Loader'; // Assuming you have a Loader component
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

const Dashboard = () => {
    const [loading, setLoading] = useState(true); // Manage loading state

    // Simulate a loading period, or use actual API calls
    useEffect(() => {
        const timeout = setTimeout(() => {
            setLoading(false); // Set loading to false after content is ready
        }, 1000); // Adjust delay as needed or remove if using real API

        return () => clearTimeout(timeout); // Cleanup timeout
    }, []);

    if (loading) {
        // Render a Loader component or a spinner during loading
        return (
            <Loader />
        );
    }

    // Render the actual content once loading is complete
    return (
        <>
            <div className="pagecontent">
                <UserNav />
                <LeftSideMenu />
                <div className="pagecontentbox">
                    <div className="pagebannerflex">
                        <div className="pagetitle col-lg-6">
                            <h2 className="pagetitleh2">Elevate Your Website Look With LandingFast Themes!</h2>
                            <p className="content userpagecontent">Discover the perfect theme for your LandingFast Store.</p>
                        </div>
                        <div className="pagebannerimgbg">
                            <Image src={`${process.env.PUBLIC_URL}/images/art.png`} className="bannerimg" alt="bannerimg" />
                        </div>
                    </div>
                </div>
                <div className="pagecontentbox">
                    <div className="text-center">
                        <h6 className="h6">
                            🎉 Let's get started! Select your{' '}
                            <span>
                                <Link to="/projects">LandingFast</Link>
                            </span>{' '}
                            theme!
                        </h6>
                        <Image src={`${process.env.PUBLIC_URL}/images/editbanner.webp`} className="welcomdashimg" alt="dashimg" />
                    </div>
                </div>
            </div>
            <ToastContainer />
        </>
    );
};

export default Dashboard;
