import React from 'react'
import { Container, Col, Row, Image, Button, Form, Modal, Badge } from "react-bootstrap";
import '../../src/timeline.css'

const Experience = () => {
    return (
        <section className='experience' id='experience'>
            <Container>
                <div className='techheader text-center'>
                    <Badge className='custombadge my-3'>Experience <span><Image src='images/star.svg' alt='star' className='badgeimg' /></span></Badge>
                    <h3 className='h3'>Professional Experiences</h3>
                    <p className='content'>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Omnis, nulla?</p>
                </div>
                <div className="timeline">
                    <ul>
                        <li>
                            <div className="timelinecontent">
                                <h3>HasoTechnology</h3>
                                <p>Fullstack Developer</p>
                            </div>
                            <div className="time">
                                <h4>January 2018</h4>
                            </div>
                        </li>

                        <li>
                            <div className="timelinecontent">
                                <h3>Pixel Web Solutions</h3>
                                <p>Frontend Developer</p>
                            </div>
                            <div className="time">
                                <h4>February 2018</h4>
                            </div>
                        </li>

                        <li>
                            <div className="timelinecontent">
                                <h3>XYX</h3>
                                <p>Frontend Developer</p>
                            </div>
                            <div className="time">
                                <h4>March 2018</h4>
                            </div>
                        </li>

                        <li>
                            <div className="timelinecontent">
                                <h3>XYX</h3>
                                <p>Frontend Developer</p>
                            </div>
                            <div className="time">
                                <h4>March 2018</h4>
                            </div>
                        </li>
                        <div className='clearclass'></div>
                    </ul>
                </div>
            </Container>
        </section>
    )
}

export default Experience