import React, { useEffect, useState } from 'react';
import { Container, Col, Row, Image, Button, Form } from "react-bootstrap";
import { Link, useNavigate } from 'react-router-dom';
import { useRegisterMutation } from '../../slices/usersApiSlice';
import { useDispatch, useSelector } from 'react-redux';
import { setCredentials } from '../../slices/authSlice';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        username: '',
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [register, { isLoading }] = useRegisterMutation();

    // Redirect if already logged in
    const { userInfo } = useSelector((state) => state.auth);
    useEffect(() => {
        if (userInfo) {
            navigate('/dashboard');
        }
    }, [userInfo, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');

        if (formData.password !== formData.confirmPassword) {
            setError('Passwords do not match');
            toast.error('Passwords do not match');
            return;
        }

        try {
            const res = await register({
                name: formData.name,
                username: formData.username,
                email: formData.email,
                password: formData.password,
                confirmPassword: formData.confirmPassword
            }).unwrap();

            dispatch(setCredentials(res));
            navigate('/dashboard');
        } catch (err) {
            const errorMsg = err?.data?.message || 'Something went wrong';
            setError(errorMsg);
            toast.error(errorMsg); // Toast for showing error popup
        }
    };

    useEffect(() => {
        document.body.classList.add('authpage');

        return () => {
            document.body.classList.remove('authpage');
        };
    }, []);

    return (
        <>
            <ToastContainer position="top-center" autoClose={3000} />
            <Image src='images/triangle.svg' className='authbgimg' alt='authbg' />
            <div className='formcontainer'>
                <div className="formboxbg">
                    <div className='authtitlebg mb-4'>
                        <h2 className='mb-3'>LandingFast 🚀</h2>
                        <h6>Get Started With LandingFast!</h6>
                    </div>
                    <Form onSubmit={handleSubmit}>
                        <Row>
                            <Form.Group as={Col} className="mb-3" controlId="formGridName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    placeholder='Tyler Durden'
                                    type="text"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group as={Col} className="mb-3" controlId="formGridName">
                                <Form.Label>Username</Form.Label>
                                <Form.Control
                                    name="username"
                                    placeholder='tyler97'
                                    type="text"
                                    value={formData.username}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGridEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    name="email"
                                    placeholder='email@example.com'
                                    type="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGridPassword">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    name="password"
                                    placeholder='*******'
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formGridConfirmPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    name="confirmPassword"
                                    placeholder='*******'
                                    type="password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                            <div className="text-center">
                                <Button
                                    className="btn w-100 authbtn sitebtn"
                                    type="submit"
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Creating Account...' : 'Get Started'}
                                </Button>
                            </div>
                            {error && <p className="text-danger mt-2">{error}</p>}
                        </Row>
                    </Form>
                </div>
                <div>
                    <h6>Already A Member <Link to='/login'>Login!</Link></h6>
                </div>
            </div>
        </>
    )
}

export default Register;
